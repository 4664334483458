import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import TableFeatures from 'components/Indoor_Cameras/IN-8001_HD/Product_Features/TableFeatures';
import TableStatus from 'components/Indoor_Cameras/IN-8001_HD/Product_Features/TableStatus';
import NavButtons from 'components/Indoor_Cameras/IN-8001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8001 Full HD Manual Product Features",
  "path": "/Indoor_Cameras/IN-8001_HD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-8001 HD is the first INSTAR camera with the new 1080p chipset.",
  "image": "./P_SearchThumb_IN-8001HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-8001HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8001 Full HD Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8001 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8001HD_Features.png' twitter='/images/Search/P_SearchThumb_IN-8001HD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8001_HD/Produkt_Features/' locationFR='/fr/Indoor_Cameras/IN-8001_HD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8001-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8001-full-hd-manual",
        "aria-label": "in 8001 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8001 Full HD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "814px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6ab05fc2c826474e61f7d42bf7285d29/a4262/IN-8001_Camera_Features.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADA0lEQVQ4y2VT227jRgzN/39SF9u0aJE+ZPOwQR5jR4ps2dZo7vcZDllIcoxk92AgDCQd8pA8vCNsBJWgYi0lBi04my5ayVoKEaH3aC3m1GL48fj45/fv9/f3fd8vnxDviLD2HbBLi8GyaToMQ9+xaXLOIVG7nIFNLScM4eXnz/8eHv7+654xhohEtJLfezifwGh9PIz73cvz8ziORhskAj7DzFrKkEvf909PP759++MreTw0zjAGfT7J8RCsMUYrpYgIpgswht57pbr9/uHff54eH8dhiCGsZETgrBmNJSt2EdOFCK0xUkoiqvvXOrxjjJZNSSnHeXLWcn4jN6JFA7YWQmAz00rObAohNEQMoXmPAEYIp9R713EhjLUppSt5aYmW4G0xqlgtxVxyAoDW2tZVIuJCsHnevb6Ox8NhGGqtVzLmhDUvUUrOKcbgc4oAQAtwfVJrzVqbU3TO+VXzTfYSf7u0BilFWAP/AkQqJddabm9W8nqwwZrVOWu8dznnTfCGUor33jvrnY0xbLq+kK0xWkujlTHaWrvVvCEEr5W6nbL676PbKx9qwda2ElprnzNvZa/AL7IxRaolxaCkkILXkolIKxXWxly7zbkQopQihJjn+YNM2DjLbDr3nTifjFYxeEKUQni/XLaM8zxrrUOMRmvG2FbRteYUfN+98Xk2WqUYaslS8BAjThMwBt6/7/eC803CMAw3MrZ5KjObT+M0DC/PzyE4IpKCL/PkvAkBObPxqKdLUFIb82kxsFEDwrYMw1lrNKyTtB8e3P6TUgopK4CUkq8SFjK2VnK+OulTe3HFV5PgLyO4SzG+7d+2VKXU1tput4txseeWOefsnUPEWmuMkYjmeR6GYSEDgBACADjni6sadl3nvc85byPRetltWLGF01pP03QlM8ZyzsYY5xwAeO9LKSmlbSSbMRdpxiilaq3OOWNMrfWOiLxz3ntjjLU2xjgMw3i84nQ6HY9j3/Vaa2ftMuoQrLHe+9ba3e/bU2stKwBgu5RcPvv81rD/AV2dK8jS8CS4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ab05fc2c826474e61f7d42bf7285d29/e4706/IN-8001_Camera_Features.avif 230w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/d1af7/IN-8001_Camera_Features.avif 460w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/8a320/IN-8001_Camera_Features.avif 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6ab05fc2c826474e61f7d42bf7285d29/a0b58/IN-8001_Camera_Features.webp 230w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/bc10c/IN-8001_Camera_Features.webp 460w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/f23e7/IN-8001_Camera_Features.webp 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ab05fc2c826474e61f7d42bf7285d29/81c8e/IN-8001_Camera_Features.png 230w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/08a84/IN-8001_Camera_Features.png 460w", "/en/static/6ab05fc2c826474e61f7d42bf7285d29/a4262/IN-8001_Camera_Features.png 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6ab05fc2c826474e61f7d42bf7285d29/a4262/IN-8001_Camera_Features.png",
              "alt": "IN-8001 Full HD Produkt Features",
              "title": "IN-8001 Full HD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" /> 
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8001 is the newest Full HD PIR Indoor and Outdoor IP Camera from INSTAR. With a built-in passive infrared (PIR) motion sensor and four separately configurable alarm areas that can be linked together, the camera prevents false alarms and notifies you only when a real alarm has been triggered.`}</p>
    <p>{`Equipped with one of the latest Panasonic Full HD CMOS image sensor, which features low noise, high speed and new Wide Dynamic Range (WDR) functionality, the IN-8001 is able to achieve the best image quality in daylight as well as clear image in night vision mode with the help of its high-power LEDs. Hence, the IN-8001 is able to deliver accurate motion detection and alarm notification.`}</p>
    <p>{`The design of this camera was completely new developed in order to fullfill the wishes of our customers. If you need to pull the cable through the wall, a small hole in the size of the cable diameter would be sufficient, as you can simply take off the cable from inside the casing, pull it through the wall and connect the cable back to the board inside.`}</p>
    <p>{`This camera is running an embedded Linux operating system and a Webserver which supports TLS/SSL encryption for security and privacy. It provides a built-in, modern HTML5 based Web User Interface (WebUI) with focus on usability and simplicity, supporting all recent devices (desktop, tablet, smartphone) and operating systems (Windows, MacOS, Linux,…). Thanks to the built-in Camera Setup Wizard and the (built-in) help function, INSTAR users can enjoy a very easy initial set-up of the IP camera.`}</p>
    <h3 {...{
      "id": "status-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-leds",
        "aria-label": "status leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LEDs`}</h3>
    <TableStatus mdxType="TableStatus" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      